<template>
  <div class="treatment-effect" style="background-color: #eee">
    <div class="head-box" style="display: flex; background-color: #fff">
      <span
        :class="['head-item', type == 1 ? 'head-item-active' : '']"
        @click="() => typeCut(1)"
        style="flex: 1"
        >工艺模式Ⅰ</span
      >
      <span
        :class="['head-item', type == 2 ? 'head-item-active' : '']"
        @click="() => typeCut(2)"
        style="flex: 1"
        >工艺模式Ⅱ</span
      >
      <span
        :class="['head-item', type == 3 ? 'head-item-active' : '']"
        @click="() => typeCut(3)"
        style="flex: 1"
        >工艺模式Ⅲ</span
      >
      <div
        class="line"
        :style="{ left: type == 1 ? '14.5%' : type == 2 ? '47.5%' : '80.1%' }"
      ></div>
    </div>
    <div
      v-show="type == 1"
      style="
        width: 100%;
        height: calc(100% - 57px);
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <div
        id="demoCanvas1"
        style="width: 95%; height: 400px; background-color: #eee"
      ></div>
      <div class="content">
        详情
        <Icon :type="type2" @click="clickxq" />
        <div v-if="n == true">
          <p>
            模式Ⅰ工艺以能源利用与综合利用为主要目的，适用于当地有较大的能源需求，沼气能完全
            利用，同时周边有足够土地消纳沼液、沼渣，并有一倍以上的土地轮作面积，使整个养殖场（区）的畜
            禽排泄物在小区域范围内全部达到循环利用的情况。
          </p>
          <p>
            粪尿连同废水一同进入厌氧反应器；未采用干清粪工艺的，应严格控制冲洗用水，提高废水
            浓度，减少废水总量。
          </p>
        </div>
      </div>
    </div>
    <div
      class="content-box1"
      v-show="type == 2"
      style="
        width: 100%;
        height: calc(100% - 57px);
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <div
        id="demoCanvas2"
        style="width: 95%; height: 400px; background-color: #eee"
      ></div>

      <div class="content">
        详情
        <Icon :type="type2" @click="clickxq" />
        <div v-if="n == true">
          <p>
            模式Ⅱ工艺适用于能源需求不大，主要以进行污染物无害化处理、降低有机物浓度、减少沼
            液和沼渣消纳所需配套的土地面积为目的，且养殖场周围具有足够土地面积全部消纳低浓度沼液，并且
            有一定的土地轮作面积的情况。
          </p>
          <p>
            废水进入厌氧反应器之前应先进行固液（干湿）分离，然后再对固体粪渣和废水分别进行处
            理。
          </p>
        </div>
      </div>
    </div>
    <div
      class="content-box1"
      v-show="type == 3"
      style="
        width: 100%;
        height: calc(100% - 57px);
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <div
        id="demoCanvas3"
        style="width: 95%; align: center; height: 400px; background-color: #eee"
      ></div>
      <div class="content">
        详情
        <Icon :type="type2" @click="clickxq" />
        <div v-if="n == true">
          <p>
            能源需求不高且沼液和沼渣无法进行土地消纳，废水必须经处理后达标排放或回用的，应采
            用模式Ⅲ处理工艺
          </p>
          <p>
            废水进入厌氧反应器之前应先进行固液（干湿）分离，然后再对固体粪渣和废水分别进行处
            理。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as SVGA from "svgaplayerweb";
export default {
  data() {
    return {
      type: 1,
      n: false,
      text: "展开",
      type2: "ios-arrow-dropup",
    };
  },
  methods: {
    clickxq() {
      this.n = !this.n;
      if (this.type2 == "ios-arrow-dropdown") this.type2 = "ios-arrow-dropup";
      else {
        this.type2 = "ios-arrow-dropdown";
      }
    },
    typeCut(type) {
      if (this.type == type) return;
      this.type = type;
      this.initSvga();
    },
    initSvga() {
      let url = `/svga/producemodel${this.type}.svga`;
      let player = new SVGA.Player(`#demoCanvas${this.type}`);
      let parser = new SVGA.Parser(`#demoCanvas${this.type}`); // Must Provide same selector eg:#demoCanvas IF support IE6+
      parser.load(url, function (videoItem) {
        player.setVideoItem(videoItem);
        player.startAnimation();
      });
    },
  },
  mounted() {
    this.initSvga();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
.content {
  background: #eee;
  font-size: 20px;
  padding: 20px 0 0 20px;
  line-height: 45px;
}
.button {
  width: 80px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: rgb(252, 250, 250);
  background: #06baa1;
  border-radius: 8px;
}
</style>